import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import styled, { DefaultTheme } from 'styled-components';
import Select from '@paljs/ui/Select';
import { LayoutHeader } from '@paljs/ui/Layout';
// import { EvaIcon } from '@paljs/ui/Icon';
// import { Button } from '@paljs/ui/Button';
import { Actions } from '@paljs/ui/Actions';
import ContextMenu from '@paljs/ui/ContextMenu';
import User from '@paljs/ui/User';
import { getPathReady } from './Sidebar';
import { Location } from '@reach/router';
import { breakpointDown } from '@paljs/ui/breakpoints';
import { toast } from 'react-toastify';
import { getLoggedInUser } from '../actions/authAction';

const HeaderStyle = styled.div`
  display: flex;
  width: 100%;
  block-sizing: fit-content;
  justify-content: space-between;
  .right > div {
    height: auto;
    display: flex;
    align-content: center;
    // margin-right: 1rem;
  }

  .logo {
    font-size: 1.25rem;
    white-space: nowrap;
    text-decoration: none;
  }
  .left {
    display: flex;
    align-items: center;
    .github {
      font-size: 18px;
      margin-right: 5px;
    }
  }

  .user-info {
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    .right > div {
      margin-right: 0;
    }
    .logo {
      display: none;
    }
  }
`;

const Label = styled.span`
  display: flex;
  align-items: center;
`;

const SelectStyled = styled(Select)`
  min-width: 150px;
`;

interface HeaderProps {
  toggleSidebar: () => void;
  theme: {
    set: (value: DefaultTheme['name']) => void;
    value: DefaultTheme['name'];
  };
  changeDir: () => void;
  dir: 'rtl' | 'ltr';
}

const Header: React.FC<HeaderProps> = (props) => {
  const [userName, setUserName] = useState('');
  const [graphNodeId, setGraphNodeId] = useState('');
  const handleLogout = () => {
    try {
      // logoutUser();
      toast.success('Logged out successfully');
    } catch (error) {
      console.error('Error logging out:', error);
      toast.error('Error logging out');
    }
  };
  // const themeOptions = [
  //   {
  //     value: 'default',
  //     label: (
  //       <Label>
  //         <EvaIcon name="droplet" options={{ fill: '#a6c1ff' }} />
  //         Default
  //       </Label>
  //     ),
  //   },
  //   {
  //     value: 'dark',
  //     label: (
  //       <Label>
  //         <EvaIcon name="droplet" options={{ fill: '#192038' }} />
  //         Dark
  //       </Label>
  //     ),
  //   },
  //   {
  //     value: 'cosmic',
  //     label: (
  //       <Label>
  //         <EvaIcon name="droplet" options={{ fill: '#5a37b8' }} />
  //         Cosmic
  //       </Label>
  //     ),
  //   },
  //   {
  //     value: 'corporate',
  //     label: (
  //       <Label>
  //         <EvaIcon name="droplet" options={{ fill: '#3366ff' }} />
  //         Corporate
  //       </Label>
  //     ),
  //     selected: true,
  //   },
  // ];
  useEffect(() => {
    const loggedInUser = getLoggedInUser();
    console.log('logged in user ', loggedInUser);
    setUserName(loggedInUser?.name);
    setGraphNodeId(loggedInUser?.graph_node_id);
  }, [getLoggedInUser()]);
  return (
    <LayoutHeader fixed>
      <HeaderStyle>
        <Actions
          size="Medium"
          actions={[
            {
              icon: { name: 'menu-2-outline' },
              url: {
                onClick: props.toggleSidebar,
              },
            },
            {
              content: (
                <Link to="/" className="logo">
                  Kutumb Admin Panel
                </Link>
              ),
            },
            // {
            //   content: (
            //     <SelectStyled
            //       isSearchable={false}
            //       shape="SemiRound"
            //       placeholder="Themes"
            //       value={themeOptions.find((item) => item.value === props.theme.value)}
            //       options={themeOptions}
            //       onChange={({ value }: { value: DefaultTheme['name'] }) => props.theme.set(value)}
            //     />
            //   ),
            // },
            // {
            //   content: (
            //     <Button size="Small" onClick={() => props.changeDir()}>
            //       {props.dir}
            //     </Button>
            //   ),
            // },
          ]}
        />
        <Actions
          size="Small"
          className="right"
          actions={[
            // {
            //   content: (
            //     <a
            //       className="left"
            //       href={`https://github.com/paljs/gatsby-admin-template`}
            //       target="_blank"
            //       rel="noreferrer"
            //     >
            //       <span className="github">Support us in GitHub</span>
            //       <img src={`https://badgen.net/github/stars/paljs/gatsby-admin-template`} />
            //     </a>
            //   ),
            // },
            // {
            //   content: (
            //     <a href="https://discord.gg/NRmdvDxsT8" target="_blank" rel="noreferrer">
            //       <img height="20" src="/discord.svg" alt="slack" />
            //     </a>
            //   ),
            // },
            // {
            //   icon: 'twitter',
            //   url: { href: 'https://twitter.com/AhmedElywh', target: '_blank' },
            // },
            {
              content: (
                <Location>
                  {({ location }) => (
                    <ContextMenu
                      style={{ cursor: 'pointer' }}
                      placement="bottom"
                      currentPath={getPathReady(location.pathname)}
                      items={[
                        // { title: 'Profile', link: { to: '/modal-overlays/tooltip' } },
                        { title: 'Change loggedIn User', link: { to: '/change-user' } },
                        { title: 'Log out', link: { to: '/auth/logout' } },
                      ]}
                      Link={Link}
                    >
                      <User image="url('/icons/icon-72x72.png')" name="Kutumb Family" title="Admin" size="Medium" />
                    </ContextMenu>
                  )}
                </Location>
              ),
            },
          ]}
        />
        <div className="user-info">
          Logged In User:{' '}
          <span style={{ color: 'blue' }}>
            <a
              onClick={() => window.open(`/family-tree?idOfUser=${graphNodeId}`, '_blank')}
              style={{ textDecoration: 'none', padding: 0, color: 'blue' }}
            >
              {userName}
            </a>
          </span>
        </div>
      </HeaderStyle>
    </LayoutHeader>
  );
};
export default Header;
