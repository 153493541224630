import helpers from './commonActions';
import jwt from 'jwt-decode';

export const storeToken = (userData) => {
  const isBrowser = typeof window !== 'undefined';
  // window.localStorage.setItem('user', userData);
  if (isBrowser) {
    window.localStorage.setItem('Token', userData.Token);
  }
};

export const getToken = () => {
  const isBrowser = typeof window !== 'undefined';
  if (isBrowser) return window.localStorage.getItem('Token') || null;
  return null;
};

export const getLoggedInUser = (mobile = false) => {
  let Token = getToken();
  if (Token) {
    const session = jwt(Token);
    return mobile ? session.user.mobile : session.user.user;
  }
  return null;
};

export const getLoggedInUserId = () => {
  const loggedInUser = getLoggedInUser();
  return loggedInUser?.graph_node_id;
};

// Login - get user token
export const loginUser = async (userData) => {
  try {
    const json = await helpers.httpPost(
      '/api/web_login',
      {
        mobile: userData.phone,
        password: userData.password,
      },
      null,
    );
    if (json?.status) {
      console.log('result from loginUser', json);

      storeToken(json);
    }
    return json;
  } catch (err) {
    console.error('Request Failed', err);
  }
};

export const sendOtpForLogin = async (req_body) => {
  try {
    let otp_response = await helpers.httpPost('/api/mobile/login', req_body, null);
    return otp_response;
  } catch (e) {
    console.error('Error in login:', e);
    throw e;
  }
};

export const verifyOtp = async (req_body) => {
  try {
    const json = await helpers.httpPost('/api/mobile/verify', req_body, null);
    if (json.status) {
      console.log('result from otp verify ', json);
      // const { Token, user } = json;
      // const userData = { ...user, Token };
      storeToken(json);
    }
    return json;
  } catch (e) {
    console.error('Error in response of verifyOTPData:', e);
    throw e;
  }
};
