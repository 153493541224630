import React, { createContext, useContext, useState } from 'react';

// Define the types for your context data
interface DataContextType {
  maleNamesData: any[] | null;
  setMaleNamesData: React.Dispatch<React.SetStateAction<any[] | null>>;
  femaleNamesData: any[] | null;
  setFemaleNamesData: React.Dispatch<React.SetStateAction<any[] | null>>;
  gautraData: any[] | null;
  setGautraData: React.Dispatch<React.SetStateAction<any[] | null>>;
  villageData: any[] | null;
  setVillageData: React.Dispatch<React.SetStateAction<any[] | null>>;
}

// Create the context
const DataContext = createContext<DataContextType | undefined>(undefined);

// Define the provider component
export const DataProvider: React.FC = ({ children }) => {
  const [maleNamesData, setMaleNamesData] = useState<any[] | null>(null);
  const [femaleNamesData, setFemaleNamesData] = useState<any[] | null>(null);
  const [gautraData, setGautraData] = useState<any[] | null>(null);
  const [villageData, setVillageData] = useState<any[] | null>(null);

  // Provide the context value
  const contextValue: DataContextType = {
    maleNamesData,
    setMaleNamesData,
    femaleNamesData,
    setFemaleNamesData,
    gautraData,
    setGautraData,
    villageData,
    setVillageData,
  };

  return <DataContext.Provider value={contextValue}>{children}</DataContext.Provider>;
};

// Define the custom hook to consume the context
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
